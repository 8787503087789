import styled from 'styled-components'
import distances from 'src/assets/styles/distances.js'
import mediaQuery from 'src/assets/styles/mediaQuery.js'

const MainWrapper = styled.div`
  padding: 0 ${distances.s}rem;
  width: calc(100% - ${distances.s * 2}rem);
  position: relative;
  height: 100%;
  overflow-y: hidden;
  @media (max-width: ${mediaQuery.tablet}) {
    padding: 0 ${distances.xs}rem;
    width: calc(100% - ${distances.xs * 2}rem);
  }
`

export default MainWrapper
