import React, { useRef, useEffect, useState } from 'react'
import ContextConsumer from 'src/layouts/Context.js'

import styled, { keyframes } from 'styled-components'
import distances from 'src/assets/styles/distances.js'
import colors from 'src/assets/styles/colors.js'
import typographySizes from 'src/assets/styles/typographySizes.js'
import mediaQuery from 'src/assets/styles/mediaQuery.js'

const HamburgerWrapper = styled.div`
  cursor: pointer;
  display: flex;
  margin-left: ${distances.m}rem;
  transform: translateY(6.5rem);
  display: none;
  @media (max-width: ${mediaQuery.tablet}) {
    display: block;
  }
`

const IconWrapper = styled.div``

const topBarAnimation = keyframes`
  0% {
    transform: translateY(-6.5rem) rotate(0);
  }

  50% {
    transform: translateY(2.5rem) rotate(0);;
  }
  100%{
    transform: translateY(2.5rem) rotate(45deg);
  }
`
const bottomBarAnimation = keyframes`
  0% {
    transform: translateY(6.5rem) rotate(0);;
  }

  50% {
    transform: translateY(-2.5rem) rotate(0);;
  }

  100%{
    transform: translateY(-2.5rem) rotate(-45deg);
  }
`

const bottomBarAnimationRev = keyframes`
  0% {
 
    transform: translateY(-2.5rem) rotate(-45deg);
  }

  50% {
    transform: translateY(-2.5rem) rotate(0);;
  }

  100%{
    transform: translateY(6.5rem) rotate(0);;
  }
`

const topBarAnimationRev = keyframes`
  0% {
    transform: translateY(2.5rem) rotate(45deg);
  }

  50% {
    transform: translateY(2.5rem) rotate(0);;
  }
  100%{
    transform: translateY(-6.5rem) rotate(0);
  }
`

const HamburgerBar = styled.div`
  height: 5rem;
  width: 50rem;
  background-color: ${colors.dark};
  position: relative;

  :first-of-type {
    transform: translateY(-6.5rem);
    transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1);
  }
  :last-of-type {
    transform: translateY(6.5rem);
    transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1);
  }

  &.clicked:first-of-type {
    animation: ${topBarAnimation} 0.5s cubic-bezier(0.65, 0, 0.35, 1) forwards;
  }
  &.clicked:last-of-type {
    animation: ${bottomBarAnimation} 0.5s cubic-bezier(0.65, 0, 0.35, 1)
      forwards;
  }

  &.clicked:first-of-type {
    animation: ${topBarAnimation} 0.5s cubic-bezier(0.65, 0, 0.35, 1) forwards;
  }
  &.clicked:last-of-type {
    animation: ${bottomBarAnimation} 0.5s cubic-bezier(0.65, 0, 0.35, 1)
      forwards;
  }
  &.noClicked.firstClicked:last-of-type {
    animation: ${bottomBarAnimationRev} 0.5s cubic-bezier(0.65, 0, 0.35, 1)
      forwards;
  }
  &.noClicked.firstClicked:first-of-type {
    animation: ${topBarAnimationRev} 0.5s cubic-bezier(0.65, 0, 0.35, 1)
      forwards;
  }
`

const Hamburger = props => {
  const [clicked, setClicked] = useState(false)
  const [firstClicked, setfirstClicked] = useState(false)

  const FnClicked = set => {
    setfirstClicked(true)
    setClicked(clicked ? false : true)
  }

  return (
    <ContextConsumer>
      {({ data, set }) => (
        <HamburgerWrapper
          onClick={() => {
            data.navOpen ? set({ navOpen: false }) : set({ navOpen: true })
            FnClicked()
          }}
        >
          <IconWrapper>
            <HamburgerBar
              className={` ${data.navOpen ? 'clicked' : 'noClicked'} ${
                firstClicked && 'firstClicked'
              } `}
            />
            <HamburgerBar
              className={` ${data.navOpen ? 'clicked' : 'noClicked'} ${
                firstClicked && 'firstClicked'
              } `}
            />
          </IconWrapper>
        </HamburgerWrapper>
      )}
    </ContextConsumer>
  )
}

export default Hamburger
