import { createGlobalStyle } from 'styled-components'
import colors from 'src/assets/styles/colors.js'
import mediaQuery from 'src/assets/styles/mediaQuery.js'

const GlobalStyle = createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        font-family: 'Space Mono', monospace;
        color: ${colors.dark};
        font-weight: lighter;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        font-size: 1px;
       
        @media (min-width: 1440px) {
            font-size: 0.0695vw;

            }
    }
    /* html{scroll-behavior: smooth;} */
    ::selection {
        color: ${colors.darkGrey};
        background: ${colors.mainColor};
;
}
 body{
     overflow-x: hidden;
     font-size: 12rem !important;
 }
 p, span, input{
     font-size: 16rem;
 }
 input::-webkit-input-placeholder {
    font-size: 16rem;
 }
 strong {

    }

    button{
        border: none;
        margin: 0;
        padding: 0;
        width: auto;
        overflow: visible;
        background: transparent;
        color: inherit;
        font: inherit;
        line-height: normal;
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        -webkit-appearance: none;

    
        
    }
    button:focus {
        outline: none;
        box-shadow: none;
        }
`

export default GlobalStyle
