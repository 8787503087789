import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import CookieConsent from 'react-cookie-consent'
import typographySizes from 'src/assets/styles/typographySizes.js'
import styled from 'styled-components'
import menuPDF from 'src/static/Polityka-prywatnosci.pdf'

import colors from 'src/assets/styles/colors.js'

const Link = styled.a`
  font-size: ${typographySizes.s}rem;
`

const Cookies = () => (
  <CookieConsent
    style={{
      background: `${colors.darkGreen}`,
      padding: '0px',
      fontSize: `${typographySizes.s}rem`,
    }}
    buttonStyle={{
      background: `#fff`,
      margin: '5px 0px 5px 15px',
      fontSize: `${typographySizes.s}rem`,
    }}
    declineButtonStyle={{
      background: `transparent`,
      margin: '5px 15px 5px 5px',
      color: `${colors.darkGrey}`,
      textDecoration: 'underline',
      fontSize: `${typographySizes.s}rem`,
    }}
    contentStyle={{ margin: '5px 15px', fontSize: `${typographySizes.s}rem` }}
    location="bottom"
    buttonText="Akceptuje"
    enableDeclineButton
    flipButtons
    declineButtonText="Nie akcpetuje"
    cookieName="gatsby-gdpr-google-analytics"
  >
    Używamy plików sushi.... ;) Używamy plików cookie.{' '}
    <Link href={menuPDF} target="blank">
      Zobacz, w jakim celu.
    </Link>
  </CookieConsent>
)

export default Cookies
